<template>
  <div>
    <vx-card :class="{'opacity-50 pointer-events-none': isLoadingInitData}">
      <div class="vx-row">
        <div class="vx-col w-full">
          <ValidationErrors :errors="errors"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Nomor</label>
          <vs-input class="w-full" v-model="data.no_keamanan" disabled/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Proyek</label>
          <v-select :options="proyeks" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_proyek" disabled/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Lokasi *</label>
          <vs-input class="w-full" v-model="data.lokasi"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Mulai *</label>
          <flat-pickr :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}" class="w-full" v-model="data.tgl_mulai"></flat-pickr>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Selesai *</label>
          <flat-pickr  class="w-full" v-model="data.tgl_akhir"></flat-pickr>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Foto *</label>
          <div class="flex items-center space-x-3">
            <vs-input class="w-full" type="file" accept="image/*" multiple v-model="data.fotoTemp" @change="onSelectedFoto"/>
            <img v-if="data.foto && !data.fotoTemp" :src="data.foto_url_list[0]" @click="showImages(data.foto_url_list)" class="w-8 h-auto" alt="photos"/>
          </div>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs">Keterangan</label>
          <vs-textarea class="w-full" v-model="data.keterangan"/>
        </div>
      </div>

      <vs-divider> Monitoring Asset </vs-divider>

      <div class="overflow-auto">
        <table class="table-fixed border-collapse w-full">
          <tr>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-10">#</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-64">Nama Asset</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-16">Jml</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-48">Rusak</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Foto</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-16">Urgent</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-64">Keterangan</th>
          </tr>
          <tr v-for="(item, index) in data.details" :key="index" class="text-sm">
            <td class="p-2 border border-solid d-theme-border-grey-light text-right">{{ index + 1 }}</td>
            <td class="p-2 border border-solid d-theme-border-grey-light text-left">{{ item.nama_asset }}</td>
            <td class="p-2 border border-solid d-theme-border-grey-light text-left">{{ item.jml_asset }}</td>
            <td class="p-2 border border-solid d-theme-border-grey-light text-left"><vs-input class="w-full" type="number" v-model="item.jml_rusak"/></td>
            <td class="p-2 border border-solid d-theme-border-grey-light text-left">
              <div class="flex items-center space-x-3">
                <vs-input class="w-full" type="file" accept="image/*" multiple v-model="item.fotoTemp" @change="onSelectedItemFoto(item, $event)"/>
                <img v-if="item.foto && !item.fotoTemp" :src="item.foto_url_list[0]" @click="showImages(item.foto_url_list)" class="w-8 h-auto" alt="photos"/>
              </div>
            </td>
            <td class="p-2 border border-solid d-theme-border-grey-light text-left"><vs-checkbox class="justify-center" v-model="item.is_urgent"/></td>
            <td class="p-2 border border-solid d-theme-border-grey-light text-left"><vs-input class="w-full" type="text" v-model="item.keterangan"/></td>
          </tr>
          <!--empty state-->
          <tr v-if="data.details.length < 1">
            <td colspan="7" class="text-sm text-center p-4">Tidak ada data.</td>
          </tr>
        </table>
      </div>

      <div class="vx-row mt-base">
        <div class="vx-col w-full sm:w-12/12">
          <div class="flex items-center justify-end">
            <vs-button class="mr-3" type="border" :to="{ name: 'estate.keamanan' }">Batal</vs-button>
            <vs-button :disabled="isLoading || isCompressingFoto" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
    </vx-card>

    <!--modals-->
    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import KeamananRepository from '@/repositories/estate/keamanan-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import ImageViewer from '@/views/components/image-viewer/ImageViewer'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { compressImage, convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'KeamananEdit',
  components: {
    ImageViewer,
    ValidationErrors,
    'v-select': vSelect,
    flatPickr
  },
  mounted () {
    this.getAllProyek()
    this.getInitData()
  },
  data () {
    return {
      isLoading: false,
      isLoadingInitData: false,
      isCompressingFoto: false,
      errors: null,
      proyeks: [],
      data: {
        details: []
      }
    }
  },
  methods: {
    async onSelectedFoto (event) {
      const images = event.target.files
      this.data.foto = await this.compressImages(images)
    },

    async onSelectedItemFoto (item, event) {
      const images = event.target.files
      const index = _.findIndex(this.data.details, it => it.uuid === item.uuid)
      this.data.details[index].foto = await this.compressImages(images)
    },

    async compressImages (images) {
      const imagesCompressed = []
      this.isCompressingFoto = true
      for (const image of images) {
        const imageCompressed = await compressImage(image)
        imagesCompressed.push(imageCompressed)
      }
      this.isCompressingFoto = false
      return imagesCompressed
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getInitData () {
      this.isLoadingInitData = true

      const idKeamanan = this.$route.params.idKeamanan
      KeamananRepository.show(idKeamanan)
        .then(response => {
          const data = response.data.data
          data.details = _.map(data.details, item => {
            item.uuid = uuid()
            return item
          })
          this.data = data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData(this.buildParams())
      KeamananRepository.update(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        id: this.data.id,
        id_proyek: this.data.id_proyek,
        tgl_mulai: this.data.tgl_mulai,
        tgl_akhir: this.data.tgl_akhir,
        lokasi: this.data.lokasi,
        foto: this.data.fotoTemp ? this.data.foto : null,
        keterangan: this.data.keterangan,
        details: _.map(this.data.details, item => {
          item.foto = item.fotoTemp ? item.foto : null
          item.is_urgent = item.is_urgent ? 1 : 0
          return _.pick(item, ['id', 'id_fixed_asset_d', 'jml_rusak', 'keterangan', 'is_urgent', 'foto'])
        })
      }
    },

    showImages (images) {
      const items = _.cloneDeep(images)
      this.$refs.viewer.show(items)
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.$router.push({ name: 'estate.keamanan' })
    }
  }
}
</script>
